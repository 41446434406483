// Post.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Posts/Post.css';
import { CiSearch } from "react-icons/ci";
import PostCard from "../Posts/PostCard";
import noPublished from '../Assets/Blog/Blog.svg'
import BlogNav from '../BlogNav';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts, fetchPublised, fetchDraft, deletePost, fetchTrashPosts, setPostLoading } from '../Slices/Post.slice';
import Loader from '../../utils/Loader';
import { farmsellApi } from '../../Api/famsellAPi';
const ListItem = ({ item, isActive, onClick, count }) => {
  return (
    <div className={`list-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      {item} ({count})
    </div>
  );
};

const Trash = ({ trash, onDelete, onRestore }) => {
  return (
    <div className="">
      {trash.length === 0 ? (
        <p className='trash-text'>No posts in trash.</p>
      ) : (
        <div>
          {trash && trash.map((post) => (
            <PostCard
              key={post.id}
              id={post.id}
              title={post.title}
              description={post.description}
              createdAt={post.createdAt}
              onDelete={() => onDelete(post.id, 'trash')}
              onRestore={()=>onRestore(post.id)} // Pass onRestore function directly
              postType="trash"
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Published = ({ posts, onDelete, id,onRestore }) => {
  const navigate = useNavigate()
  console.log(id)
  const handleView = (postId) => {
    navigate(`/post/${postId}`);
  };

  return (
    <div className="" >
      {posts.length === 0 ? (
        <div className='no-published-wrapper'>
          <div className='no-img'>
            <img src={noPublished} alt='no published content' />
          </div>
          <div className='no-text'>
            <p className='no-p'>
              Opps! Looks like you haven’t published any public blogs yet, Check in your <span className='no-drafts'>drafts</span> or write
              a new blog
            </p>
          </div>
        </div>
      ) : (
        <div>
          {posts && posts.map(post => (
            <PostCard
              key={post.id}
              id={post.id}
              title={post.title}
              description={post.description}
              createdAt={post.createdAt}
              //onEdit={() => handleEdit(post.id)}
              onDelete={() => onDelete(post.id, 'published')}
              postType="published"
            />

          ))
          }

        </div>
      )}

    </div>
  );
};

const Drafts = ({ drafts, onDelete }) => {
  const handleEdit = (postId) => {
    console.log(`Editing draft ${postId}`);
  };

  return (
    <div className="">
      {drafts.map((draft) => (
        <PostCard
          key={draft.id}
          id={draft.id}
          title={draft.title}
          description={draft.description}
          createdAt={draft.createdAt}
          onEdit={() => handleEdit(draft.id)}
          onDelete={() => onDelete(draft.id, 'draft')}
          postType="draft"
        />
      ))}
    </div>
  );
};

export default function Post() {
  const [activeComponent, setActiveComponent] = useState('Published');
  const [drafts, setDrafts] = useState([]);
  const [published, setPublished] = useState([]);
  const [trash, setTrash] = useState([]);
  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch()
  const id = useSelector(state => state.users.user?.user?.id);
  const loading = useSelector(state => state.users.loading);
  const post_loading = useSelector(state => state.posts.loading);
  const PostLoading = useSelector(state => state.posts.postLoading)

  const postsdata = useSelector(state => state.posts.user_posts);
  const PublishedPosts = useSelector(state => state.posts.PublisedPosts)
  const draftPosts = useSelector(state => state.posts.DraftPosts)
  const trashPosts = useSelector(state => state.posts.TrashPosts)
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const postsPerPage = 8;
  console.log(PublishedPosts)

  console.log(draftPosts)
  console.log(id)

  const filteredPosts = postsdata.filter(post => post.title.toLowerCase().includes(query.toLowerCase()));
  const filtedDafts = draftPosts.filter(e => e.title.toLowerCase().includes(query.toLowerCase()))


  useEffect(() => {
    dispatch(fetchPublised(id))
    dispatch(fetchPosts(id))
    dispatch(fetchDraft(id))
    dispatch(fetchTrashPosts(id))


    // Initialize data or fetch data from API
    const initialDrafts = [
      { id: 1, title: 'Agric-tech using Ai', description: 'How Agriculture has been transformed by Ai and how it has been affected.', createdAt: new Date(), type: 'draft' },
      { id: 2, title: 'Agric-tech using Ai', description: 'How Agriculture has been transformed by Ai and how it has been affected.', createdAt: new Date(Date.now() - 3600000), type: 'draft' },
    ];
    const initialTrash = [
      { id: 3, title: 'Deleted Post 1', description: 'Description of Deleted Post 1', createdAt: new Date(), type: 'trash' },
      { id: 4, title: 'Deleted Post 2', description: 'Description of Deleted Post 2', createdAt: new Date(Date.now() - 3600000), type: 'trash' },
    ];

    setDrafts(initialDrafts);

    setTrash(initialTrash);
  }, [id, dispatch]);

  useEffect(() => {
    // Update counts whenever data changes
    const draftCount = filtedDafts.length;
    const publishedCount = filteredPosts.length;
    const trashCount = trashPosts.length;
    console.log("Draft Count:", draftCount);
    console.log("Published Count:", publishedCount);
    console.log("Trash Count:", trashCount);
  }, [drafts, published, trash]);

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  if (filtedDafts != null && filteredPosts != null) {
    dispatch(setPostLoading(false))
  }

  const handleDelete = async (id, type) => {
    dispatch(deletePost({ id: id, type }));
    window.location.reload();
  };
  const handleEdit = (postId) => {
    console.log("editing")
  }

  const handleRestore = (id) => {
   
    try {
      const response = farmsellApi.put(`/api/posts/restore/${id}`)
      console.log(response)
      window.location.reload();
    } catch (error) {
      console.log("restore  post", error)
    }
  };


  const categories = ['Drafts', 'Published', 'Trash'];


  const handleChange = (e) => {
    setQuery(e.target.value);

  };




  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
  console.log(filtedDafts.id)
  console.log(filteredPosts.id)

  return (
    <div className=''>
      <div><BlogNav /></div>
      <div className='post-wrapper'>
        <div className='post-header'>
          <div><h3 className='post-header-h3'>All your blogs</h3></div>
          <div className='header-button'>
            <Link to="/Blog"> <button className='bg-button'>Write a blog</button></Link>
            <Link to="/"><button className='bg-button1'>Import a blog</button></Link>

          </div>
        </div>
        <div className="search-container">
          <input type="text" placeholder="For easy find, enter a key word or a title of what you are looking for." className="search-input" value={query} onChange={handleChange} />
          <button className="search-button">
            <CiSearch />
          </button>
        </div>

        <div className='my-post'>
          <div className='post-items'>
            <div className="categories">
              {categories.map((category, index) => (
                <ListItem
                  key={index}
                  item={category}
                  isActive={category === activeComponent}
                  onClick={() => handleComponentChange(category)}
                  count={category === 'Drafts' ? filtedDafts.length : category === 'Published' ? filteredPosts.length : trashPosts.length}
                />
              ))}
            </div>
            <div className='card-line1'></div>
            {PostLoading ? <div><Loader /></div> : activeComponent === 'Published' && <Published posts={currentPosts} onDelete={handleDelete} id={filteredPosts.id} onEdit={handleEdit} />}
            {PostLoading ? <div><Loader /></div> : activeComponent === 'Drafts' && <Drafts drafts={filtedDafts.slice(indexOfFirstPost, indexOfLastPost)} id={filtedDafts.id} onDelete={handleDelete} />}
            {PostLoading ? <div><Loader /></div> : activeComponent === 'Trash' && <Trash trash={trashPosts} onDelete={handleDelete} onRestore={handleRestore} />} {/* Pass onRestore function */}
          </div>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
