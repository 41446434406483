// src/store/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { farmsellApi } from '../../Api/famsellAPi';
import axios from 'axios';
import Cookies from 'js-cookie';

// Define an initial state for the user slice
const initialState = {
  currentUser: null,
  isAuthenticated:false,
  userLoading:false,
  loading: false,
  error: null,
  UserError:null,
  userProfilePic:null,
  users: [],
  user:null
};

// Define a thunk to post user data asynchronously
export const postUser = createAsyncThunk('users/postUserFromResponse', async (response) => {
  try {
    const { user, token } = response.data;
    
    // Set the token in the local storage
    
  
    Cookies.set('token', token, { expires: 1 })
    // Return the user details
    return response.data; // Assuming response contains the user details
  } catch (error) {
    throw new Error('Failed to create account');
  }
});
export const updateUserEmail = createAsyncThunk(
  'users/updateUserEmail',
  async ({ id, email ,token}) => {
    try {
      // Replace the endpoint with your actual API endpoint
      const response = await farmsellApi.put(`/update/:id${id}`, {
        email,
      },{
        headers:{
          Authorization:`Bearer ${token}`
        }
      });
      return response.data; // Assuming response contains the updated user data
    } catch (error) {
      throw new Error('Failed to update user email');
    }
  }
);

// Define a thunk to fetch users asynchronously
export const loginUser = createAsyncThunk('users/fetchUsers', async () => {
  try {
    const response = await farmsellApi.get('/login'); // Replace with your actual API endpoint
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users');
  }
});
export const getUser = createAsyncThunk('users/fetchUser', async () => {
  try {
    const token = Cookies.get('token');
    console.log(token);

    const response = await farmsellApi.get('/user', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); // Replace with your actual API endpoint

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users');
  }
});

// Define a thunk to authenticate user
export const authenticateUser = createAsyncThunk('users/authenticateUser', async (response) => {
  try {
    return response.data;
  } catch (error) {
    throw new Error('Failed to authenticate user');
  }
});


// Create a user slice
const UserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.isAuthenticated = true;
      
    },
    logoutUser: (state) => {
      state.currentUser = null;
      Cookies.remove("token");
      state.isAuthenticated = false;
      
    },
    setProfilepic:(state,action)=>{
state.userProfilePic = action.payload
    },
    setAuthenticated:(state)=>{
      state.isAuthenticated = true
    },
    setUserErrors:(state,action)=>{
state.UserError = action.payload
    },
    setUserLoading:(state,action)=>{
      state.userLoading =action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.isAuthenticated = true;
       
        
      })
      .addCase(postUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUserEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserEmail.fulfilled, (state, action) => {
        state.loading = false;
        // Update user email in state
        state.currentUser.email = action.payload.email;
      })
      .addCase(updateUserEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      
  },
});

// Export actions and reducer
export const { setUser, logoutUser,setAuthenticated,setProfilepic,setUserErrors,setUserLoading } = UserSlice.actions;
export default UserSlice.reducer;
