import React from 'react'
import { useNavigate } from 'react-router';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import SeoComponent from './SeoComponent';
export function PostHeroCard({ post }) {
    const settings = {
        dots: true,
       
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div>
                <ul>{dots}</ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: i === 0 ? "green" : "gray",
                }}
            />
        )
    };
    const posts = [post]
    const item = [post]
    console.log("itmes",item)
    console.log("post",post)
console.log(Object.entries(post))
    return (
        <Slider {...settings}>
        {post && post.length > 0 ? post.map((post, index) => (
            <div key={index} className="main-wrapper">
                <div className="hero-wrapper-div">
                    <div className="hero-cover-image">
                        <img src={post.coverPhoto ? post.coverPhoto : "loading data"} alt="Cover" />
                    </div>
                    <div className="post-details-hero-card">
                        <h2>{post.title}</h2>
                        <div className="details-wrapper-row">
                            <img src={post.profilePhoto ? post.profilePhoto : "loading data"} alt="Author" />
                            <div className="post-details-column">
                                <div className="post-details-hero">
                                    <p className="author-name-hero">{post.author ? post.author : "Williams"}</p>
                                    <div className="post-details">
                                        <p className="date-of-post">{post.date ? post.date : "May 03, 2014"}</p>
                                        <span className="green-dot"></span>
                                        <p className="post-read-period">{post.readTime ? post.readTime : "3 min read"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )) : null}
    </Slider>
    );
}
export function PostCard({ post }) {
    const navigate = useNavigate();
    const handleNavigation = (title) => {
        const urlFriendlyTitle = encodeURIComponent(title.toLowerCase().replace(/ /g, '-'));
        navigate(`/post/${urlFriendlyTitle}`);
    };
    console.log(post)
    const posts = [post]
    console.log(posts)
    return (
        <div className='grid-item'>
            {posts&& posts.length >0? posts.map((postItem, key) => {
                return (
                    <div key={key} className='post-card-blog' onClick={() => handleNavigation(postItem.title)}>
                        <SeoComponent title={postItem.metaTitle ?? null} description={postItem.metadescription ?? null}/>
                        <div className='card-image'>
                            <img src={postItem.coverPhoto || "loading data"} alt='Cover' />
                        </div>
                        <h3 className='post-card-title'>{postItem.title || "Title of the card"}</h3>
                        <p className='post-card-description'>{postItem.description || "Description of the card"}</p>
                        <div className='divider'></div>
                        <div className='post-details-hero-card' style={{ margin: 0 }}>
                            <div className='details-wrapper-row'>
                                <img src={postItem.profilePhoto || "loading data"} alt='Profile' />
                                <div className='post-details-colum'>
                                    <div className='post-details-hero'>
                                        <p className='author-name-hero'>{postItem.username || "Author Name"}</p>
                                        <div className='post-details'>
                                            <p className='date-of-post'>{postItem.Created || "Date of Post"}</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }) : (
                <div>No posts available</div>
            )}
        </div>
    );
}
