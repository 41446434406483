import React, { useState ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import store from '../Store/Store'
import UserSlice, { authenticateUser, getUser, setAuthenticated } from '../Slices/User.slice';
import {fetchPosts, getPostsId} from '../Slices/Post.slice'
import googleIcon from '../Assets/Blog/🦆 icon _google_.svg';
import facebookIcon from '../Assets/Blog/🦆 icon _Facebook v1_.svg';
import createHero from '../Assets/Blog/OBJECTS (1).svg';
import createLogo from '../Assets/Blog/Layer 2.svg';
import mainIcon from '../Assets/Blog/mail.svg';
import passwordicon from '../Assets/Blog/key.svg';
import { farmsellApi } from '../../Api/famsellAPi';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import axios from 'axios';
import useToken from 'antd/es/theme/useToken';
import Loader from '../../utils/Loader';
import useError from '../../Hooks/useError';
import ErrorComponent from '../../utils/ErrorComponent';
import BottonLoader from '../../utils/BottonLoader';
import { setUserLoading } from '../Slices/User.slice';
export default function SignIn() {
  const [error, setError] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [ profile, setProfile ] = useState([]);
  const [ user, setUser ] = useState([]);
  const {setUserError,userError} =useError()
  const loading = useSelector(state => state.users.loading);
  const userloading = useSelector(state => state.users.userLoading);
  // const id = useSelector(state => state.users.currentUser.user.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  if(!email && !password){
    return setUserError("Email or Password is Missing")
  }
  dispatch(setUserLoading(true))
    try {
      const response = await farmsellApi.post('/login', { email, password });
      console.log(response);
      const { token } = response.data;
    
      // Set the token in the local storage
      Cookies.set('token', token, { expires: 1 })
      dispatch(getUser())
      dispatch(authenticateUser(response));
      dispatch(setUserLoading(false))
    } catch (error) {
      setUserError(error.message)
      console.error('Authentication error:', error.message);
    }
  };
  

    if (isAuthenticated) {
      navigate('/post');
    
    }
   
      const handleGoogleSignIn = useGoogleLogin({
        onSuccess: async(googleUser) => {
          console.log(googleUser)
          const idToken = googleUser.access_token;
          console.log("id token",idToken)
          const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          })
          const data = response.data
          console.log(data)
             await  farmsellApi.post('/google-auth', {name:data.name,email:data.email,picture:data.picture}) // Replace 'your_client_id' with your actual Google client ID
            .then(response =>{ const { token } = response.data;Cookies.set('token', token, { expires: 1 }); dispatch(getUser())
            dispatch(authenticateUser(response));})
            .catch(error => console.error('Error:', error));
        
        },

      
        onFailure: (error) => {
          console.error('Login Failed:', error);
        }
      });
    
    
console.log(user)
  const handleFacebookSignIn = async () => {
    try {
       dispatch(authenticateUser({ provider: 'facebook' }));
    } catch (error) {
      console.error('Facebook authentication error:', error.message);
    }
  };
//   useEffect(
//     () => {
//         if (user) {
//             axios
//                 .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
//                     headers: {
//                         Authorization: `Bearer ${user.access_token}`,
//                         Accept: 'application/json'
//                     }
//                 })
//                 .then((res) => {
//                     setProfile(res.data);
//                 })
//                 .catch((err) => console.log(err));
//         }
//     },
//     [ user ]
// );
if(loading){
  return (<div><Loader/></div>)
}
  return (
    <div className="create-wraper">
      <div className="create-left signin-left">
        <img src={createHero} alt="Create Hero" />
      </div>
      <div className="create-right">
        <div className="create-right-wraper">
          <div className="create-nav">
            <img src={createLogo} alt="Create Logo" />
            <button className="create-nav-blog" onClick={()=>navigate('/blogs')}>Blog</button>
          </div>
          <div className="create-h3">
            <h3>Sign In</h3>
          </div>
         
         
          <div className="create-account-form-container">
          <div className="sign-in-options">
          <div className="create-divide">
            <h3 className='create-OR'>- OR -</h3>
          </div>
            <button className="google-signin" onClick={handleGoogleSignIn}>
              <img src={googleIcon} alt="Google Icon" />
              Sign in with Google
            </button>
          
          </div>
            <form className="create-account-form">
              <div className="form-group">
                <img src={mainIcon} alt="Main Icon" />
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email Address"
                  required
                />
              </div>
              <div className="form-group">
                <img src={passwordicon} alt="Password Icon" />
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  required
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash color="#9D9D9D" /> : <FaEye color="#9D9D9D" />}
                </span>
              </div>
              <span className="forgot-password" onClick={()=>navigate('/enter-email')}>I forgot my password</span>
              <button type="submit" className="submit-btn" onClick={handleSubmit}>
               {userloading?<BottonLoader/>:'Sign in'}
              </button>
              {userError && <ErrorComponent error={userError}/>}
            </form>
          </div>
          <div className="create-option">
            <p>
              No Account? <Link to="/create"><span>Create One!</span></Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
