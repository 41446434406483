import React,{useCallback, useState,} from 'react'
import { useNavigate } from 'react-router'
import BlogNav from '../BlogNav'
import OuterNavBar from '../../Pages/OuterNavBar'
import '../UserProfile/user.css'
import { farmsellApi } from '../../Api/famsellAPi'
export default function EmailSubmit() {

    const navigate = useNavigate()
    const [responsemessage,setmessage] = useState('')
    const [Error,setError] = useState('')
const [email,setemail] = useState('')
const handleChange = (e)=>{

    setemail(e.target.value)
}
const handleSubmit = useCallback( async(e)=>{
    e.preventDefault()
    if(email === ''){
       return  setError("Please enter your email!")
    }
    try{
        const response = await farmsellApi.post("/forgetPassword/",{
            email
        })
        console.log(response)
        if(response) {
           setmessage("Please check your email for the  next steps")
        }
        
    }catch(error){
 setmessage("internal server down please try again later")
    }
   
   
},[email])
  return (
    <div className=''>
      <div><OuterNavBar/></div>
      <div className='email-submit-wrapper' >
        <div className='input-wrapper' ><label htmlFor='email'>Email</label>
        <input type='email' id='email' placeholder='Please enter your email' onChange={handleChange}/></div>
        <p>{Error}</p>
        <button className='submit-button-email' onClick={handleSubmit}> Submit Email </button>
        <div >
             <p>{responsemessage}</p>
            </div>
      </div>
    </div>
  )
}
