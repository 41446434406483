import React, { useState } from 'react';

import '../UserProfile/user.css'
import axios from 'axios';
import userPic from '../Assets/Blog/Ellipse 1.svg';
import Edit from '../Assets/Blog/Edit.svg';
import Editp from '../Assets/Blog/edit p.svg';
import BlogNav from '../BlogNav';
import { setProfilepic } from '../Slices/User.slice';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Loader from '../../utils/Loader';
import { farmsellApi } from '../../Api/famsellAPi';
import OuterNavBar from '../../Pages/OuterNavBar';
import { useParams } from 'react-router';
export default function Resetpassword() {
    const profilepic = useSelector(state => state.users.userProfilePic)
  
  const [editableFields, setEditableFields] = useState({
   
    newPassword: '',
    confirmPassword: ''
  });
 
  const loading = useSelector(state => state.users.loading);
console.log(editableFields)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableFields({ ...editableFields, [name]: value });
  };

  
 
  console.log(profilepic)
  const token = useParams()
  const handleSaveChanges = async () => {
    if (editableFields.newPassword !== editableFields.confirmPassword) {
      console.error('New password and confirm password do not match');
      // Optionally, you can display an error message to the user
      return;
    }
    try {
      const response = await farmsellApi.put(`http://localhost:4000/resetPassword/`, {password:editableFields.confirmPassword}, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        }
      });
     
      // If needed, dispatch an action to update local state with the changes
    } catch (error) {
      console.error('Error while saving changes:', error);
    }
  };
  if(loading){
    return (
      <div><Loader/></div>
    )
    }
  return (
    <div>
      <div><OuterNavBar /></div>
      <div className='user-wrapper'>
       
        <div className="input-container">
          <div className='item-colum'>
            
           
            <div className="input-wrapper">
              <div>

                <h3>Find your Famsell account account</h3>
              </div>
              <p>
                
              </p>
              <label htmlFor="newPassword">New Password</label>
              <input type="password" id="newPassword" name="newPassword" value={editableFields.newPassword} onChange={handleInputChange} />
            </div>
            <div className="input-wrapper">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input type="password" id="confirmPassword" name="confirmPassword" value={editableFields.confirmPassword} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <button onClick={handleSaveChanges}>Save</button>
      </div>
    </div>
  );
}
