import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './Blog.css';
import { AuthorDetails, Comment, RecentPosts, Categories, Tags, ShareButtons } from '../../Features/postFeatures';
import BlogNav from '../../Components/BlogNav';
import { setPostsData } from '../../Components/Slices/Posts.slice';
import { farmsellApi } from '../../Api/famsellAPi';
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import Loader from '../../utils/Loader';
import dayjs from 'dayjs';
import userPic from '../../Components/Assets/Blog/Ellipse 1.svg'
import { setLoading } from '../../Components/Slices/Loadind.slice';
import Footer from '../Footer';
import EditorTextParser from '../../Components/blogPost/Editor/Editor-parser/Editor_parser';
import { BiLogoPostgresql } from 'react-icons/bi';
import useFetchPosts from '../../Hooks/useFetchPosts';
import OuterNavBar from '../OuterNavBar';
import { setrentPostsLoading,setPostsLoading } from '../../Components/Slices/Posts.slice';
import SeoComponent from '../../Features/SeoComponent';
export default function BlogPostUI() {
  const dispatch = useDispatch();
  const [post, setPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const { title } = useParams(); // Get the title from the URL
  const BlogPosts = useSelector((state) => state.BlogPosts.posts);
  const recentPosts = useSelector((state) => state.BlogPosts.recentPosts);
  const loading = useSelector((state) => state.loading.loading);
  const rentLoading = useSelector((state)=> state.BlogPosts.rentPostsLoading)
  const postLoading = useSelector((state)=> state.BlogPosts.postsLoading)
  console.log(BlogPosts);

  //follow us buttons of social media
  const twitterProfileUrl = 'https://twitter.com/FarmsellApp'
  const fbProfileUrl = 'https://www.facebook.com/farmsellapp'
  const ytProfileUrl = 'https://www.youtube.com/channel/UCMpw1sXyo9NGFgGuFHYkz7w'
  const linkProfileUrl = 'https://www.linkedin.com/company/farmsell33'
console.log('Blogposts',BlogPosts)
if(BlogPosts.length > 0 ){
  dispatch(setrentPostsLoading(false))
 
}
const { fetchPosts } = useFetchPosts('/post/recent', {
  params: {
    
    pageSize: 6,
  }
})
const { fetchData } = useFetchPosts('/posts', {
  params: {
    
    pageSize: 6,
  }
})
useEffect(() => {
  fetchPosts();
  fetchData();
}, [rentLoading,postLoading]);
  useEffect(() => {

    if (BlogPosts || title) {
      const formattedTitle = decodeURIComponent(title).replace(/-/g, ' ');
      console.log('formatted title',formattedTitle) // Decode and format the title
      const postItem = BlogPosts.find((post) => post.title.toLowerCase() === formattedTitle.toLowerCase());
      console.log('Post Item',postItem)
      if (postItem) {
        const formattedPost = {
          key: postItem.id,
          data: JSON.parse(postItem.data)?.rawdata ||null,
          description: postItem.description,
          title: postItem.title,
          metaTitle: postItem.metaTitle,
          metadescription: postItem.metadescription,
          Categories: JSON.parse(postItem.categories).categories,
          coverPhoto: postItem.coverImgUrl,
          profilePhoto: postItem.user.UserPic || userPic,
          username: postItem.user.name,
          Created: dayjs(postItem.createdAt).format('MMMM DD, YYYY')
        };
        setPost(formattedPost);
      }
    }
    if(recentPosts){
      const formattedPosts = recentPosts.map((post) => {
        const parsedData = post.data ? JSON.parse(post.data)?.rawdata : null;
        const parsedCategories = post.categories ? JSON.parse(post.categories).categories : [];
        return {
          key: post.id,
          data: parsedData,
          description: post.description,
          title: post.title,
          metaTitle: post.metaTitle,
          metadescription: post.metadescription,
          Categories: parsedCategories,
          coverPhoto: post.coverImgUrl,
          profilePhoto: post.user?.UserPic || userPic,
          username: post.user?.name || 'Unknown Author',
          Created: dayjs(post.createdAt).format('MMMM DD, YYYY'),
        };
      });
      setPosts(formattedPosts)
    }
  }, [BlogPosts,recentPosts]);
  console.log('post data',posts)
console.log("posts recent",posts)

  if (postLoading && rentLoading) {
    return(
      <div>
         <Loader />
      </div>
     )
  }

  return (
<>

<div className='post-wrapper'>
      <OuterNavBar />
      <div className='post-main-wrapper'>
        <div className='main'>
          <div className='post-cover-image'>
            <img src={post ? post.coverPhoto : null} alt='cover-picture' />
          </div>
          <h3 className='post-title'>{post ? post.title : null}</h3>
          <AuthorDetails
            profilePhoto={post ? post.profilePhoto : null}
            posted={post ? post.Created : null}
            name={post ? post.username : "loading data"}
          />
          <div className='text-div'>
            {post &&post.data ? <EditorTextParser data={post.data} /> : null}
          </div>
          <div className='divider'></div>
          <ShareButtons  title={title}/>
          {/* <div className='divider'></div>
          <Comment /> */}
        </div>
        <div className='side'>
          <div className='side-search-bar'>
            <input type='text' placeholder='search..' />
            <RecentPosts
            posts={posts}
            />
            <Categories categorie={post ? post.Categories : "loading data"} />
            <div className='social-links-div'>
              follow:<div className='social-links'>
            
          <div className="" onClick={()=>{window.location.href=fbProfileUrl}}>
            <FaFacebookF/>
          </div>
          <div className="" onClick={()=>{window.location.href=twitterProfileUrl}}>
          <FaTwitter />
          </div>
       
          <div className="" onClick={()=>{window.location.href=linkProfileUrl}}>
          <FaLinkedinIn />
          </div>
          <div className="" onClick={()=>{window.location.href=linkProfileUrl}}>
          <FaInstagram />
          </div>
               
                
              </div>
            </div>
            <Tags />
          </div>
        </div>
      </div>
    </div>
    <Footer/>
</>   
  );
}
