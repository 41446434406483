import React from 'react'
import '../Statistics/Stat.css'
import BlogNav from '../BlogNav'

export default function Stat() {
  return (
    <div >
        <div><BlogNav/></div>
      <div className='stat-wrapper'>

      <div className='stat-header'>
       
       <h3 className='stat-h3'>
           Your blog stats
       </h3>
  
  
       <button className='stat-btn'> All blogs</button>
   
 </div>
<div className='stat-parent'>
<div className='stat-item'>
   <h3 className='stat-item-h3'>0</h3>
   <p className='stat-item-h3'>Comments</p>
</div>
<div className='stat-item'>
   <h3 className='stat-item-h3'>0</h3>
   <p className='stat-item-h3'>Reactions</p>
</div>
<div className='stat-item'>
   <h3 className='stat-item-h3'>0</h3>
   <p className='stat-item-h3'>Shared</p>
</div>
<div className='stat-item'>
   <h3 className='stat-item-h3'>0</h3>
   <p className='stat-item-h3'>Clicked</p>
</div>
</div>
      </div>
    </div>
  )
}
