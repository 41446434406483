import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../blogPost/index.css'
import '../navbar.css'
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router';
import { SlArrowUp } from "react-icons/sl";
import tooltipMenue from '../Assets/Blog/image 12.svg'
import Add from '../Assets/Blog/Add.svg'
import Editor from "./Editor/Editor";
import axios from 'axios';
import EditorTextParser from "./Editor/Editor-parser/Editor_parser";
// import exampleData from "./Editor/data/Data";
import { setData, setTitle, setCoverPhotoUrl, setCategories, fetchPostById,setUpdatePost } from '../Slices/Post.slice';
import { logoutUser } from '../Slices/User.slice';
//nav
import { useData } from '../Preview/GetData';
import createLogo from '../Assets/Blog/Layer 2.svg'
import userPic from '../Assets/Blog/Ellipse 1.svg'
import NavProfile from '../Assets/Blog/profile.svg'
import navBlog from '../Assets/Blog/Blog (1).svg'
import navStat from '../Assets/Blog/Stats.svg'
import navSignout from '../Assets/Blog/log out.svg'
import { SlArrowDown } from "react-icons/sl";
import {Preview} from '../Preview/Preview';
import { current } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import Loader from '../../utils/Loader';
const NavBlog = () => {
    const dispatch = useDispatch()
    const profilepic = useSelector(state => state.users.userProfilePic)
    const currentUser = useSelector(state => state.users?.user?.user);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const navigate = useNavigate()
    const toggleProfileMenu = () => {
        setShowProfileMenu(!showProfileMenu);
    };
    console.log(profilepic)
   
    const username = useSelector(state => state.users.user?.user?.name);
    
    return (
        <div className='blog-nav-wrapper'>
            <div className='nav-logo' >
                <img src={createLogo} onClick={()=>{navigate('/');dispatch(logoutUser(null))}}/>
                <button className='create-nav-blog'>Blog</button>
            </div>
            <div>
                <div className="navbar-profile" >
                    <img src={currentUser &&currentUser.UserPic !==null ?currentUser.UserPic:userPic} alt="Profile Picture" onClick={() => navigate('/user')} />
                    <span>{currentUser?.name}</span>
                    <span onClick={toggleProfileMenu}>{showProfileMenu ? <SlArrowUp /> : <SlArrowDown />}</span>
                </div>

                {showProfileMenu && (
                    <div className="profile-menu">
                        <ul>
                            <li onClick={()=>navigate('/user')}>
                                <img src={NavProfile} alt='nav profile' />
                                <p>Profile</p>
                            </li>
                            <li onClick={()=>navigate('/blogs')}>
                                <img src={navBlog} alt='nav blog' />
                                <p>Blogs</p>
                            </li>
                            <li onClick={()=>navigate('/stat')}>
                                <img src={navStat} alt='nav statistics' />
                                <p>Statistics</p>
                            </li>
                        </ul>
                        <button onClick={()=>dispatch(logoutUser(null))}>
                            <img src={navSignout} alt='sig out' />
                            <span>Sign out</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
export default function Index() {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const { data, setData } = useData()
    console.log(data)
    const [openPreview, setPreview] = useState(false)
    const [openCategory, setCategory] = useState(false);
    const [openPublish, setPublish] = useState(false)
    const [openComment, setComment] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false);
  const [posttitle,setPosttitle] = useState("")
    const [value, onChange] = useState(new Date());
    const postsdata = useSelector((state) => state.posts.singlepost);
    const titl = postsdata ? postsdata.title : null;
    const postData = postsdata ? JSON.parse(postsdata.data) : null;
    const titlep = useSelector((state) => state.posts.title);
    const title = titl?titl:titlep
    const coverImg = useSelector(state => state.posts.coverPhotoUrl)
    const exampleData = useSelector(state => state.posts.data)
    const dispatch = useDispatch()
    const [uploadedPhoto, setUploadedPhoto] = useState(null);
    const [openShedule, setOpenSchudule] = useState()
    const username = useSelector(state => state.users.user?.user?.name);
    const loading = useSelector(state => state.users.loading);
    const updatePost = useSelector(state => state.posts.updatePost)
    const PostLoading = useSelector(state => state.posts.postLoading)
   
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file selected by the user
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                uploadFile(file) // Update the state with the base64 encoded image URL
            };
        }
    };
    
    const { postid } = useParams();
    useEffect(() => {
        // Fetch post data by ID when component mounts
        dispatch(fetchPostById(postid));
        if(postid){
            dispatch(setUpdatePost(true))
        }
    }, [dispatch, postid]);
    
    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        axios.post('http://localhost:4000/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              console.log(response.data.paths[0])
              const imageUrl = response.data.paths[0];
              dispatch(setCoverPhotoUrl(`http://localhost:4000${imageUrl}`))
              setUploadedPhoto(`http://localhost:4000/${imageUrl}`)

            }).catch((error) => {
                console.error('Error uploading file:', error);
            });
    };

    
    const handtitleChange = (e) => {
        setPosttitle()
        dispatch(setTitle(e.target.value))
    }

    const categoriesList = [
        {
            id: 1,
            name: "business"
        }
        ,
        {
            id: 2,
            name: "Cat"
        },
        {
            id: 3,
            name: "Categ 1"
        }, {
            id: 4,
            name: "Categores]]]]]]] 1"
        }
    ]
    const ScheduleList = [
        {
            name: "Automatic"
        },
        {
            name: "Set date and time"
        }
    ]
    console.log(postsdata)
    const Checkbox = ({ isChecked, label, checkHandler, index }) => {
        return (
            <div className='categories-ul-li'>
                <input
                    type="checkbox"
                    id={`checkbox-${index}`}
                    checked={isChecked}
                    onChange={checkHandler}
                />
                <label htmlFor={`checkbox-${index}`}>{label}</label>
            </div>
        )
    }
    const [checkedState, setCheckedState] = useState(categoriesList);
    console.log(checkedState)
    const handleOnChecked = (index) => {
        setCheckedState(
            checkedState.map((category, currentIndex) =>
                currentIndex === index
                    ? { ...category, checked: !category.checked }
                    : category
            )
        )
    };
    const selectedCategoryNames = checkedState
        .filter(category => category.checked)
        .map(category => category.name);
    dispatch(setCategories(selectedCategoryNames))
    console.log(selectedCategoryNames);
    const [selectedOption, setSelectedOption] = useState('automatic');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [commentOption, setCommentOption] = useState('automatic');

    const handleCommentChange = (event) => {
        setCommentOption(event.target.value);
    };
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleClick = event => {
        setCategory(current => !current);
    };
    const handleClickSchudule = event => {
        setOpenSchudule(current => !current);
    };
    function toggleEditMode() {
        if (isEditMode) {
            setIsEditMode(false);
            console.log("Edit mode is now disabled");
        } else {
            setIsEditMode(true);
            console.log("Edit mode is now enabled");
        }
    }
    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };
    const fileInputRef = React.createRef(); // Create a ref for the file input
    if(PostLoading){
        return (
          <div><Loader/></div>
        )
        }
        console.log('coverImage',postData?.coverImgUrl)
    return (

        <div className='post-wrapper1'>

            {!openPreview && (
                <div>
                    <div className='nav-wrapper'><NavBlog /></div>
                    <div className='post-publish-wrapper'>

                        <div className='post-publish'>
                            <div className='tooltipblog'></div>
                            <button className='publish-btn' onClick={(event) => setPreview(current => !current)}>publish</button>


                        </div>
                    </div>

                    <div className='post-edit'>
                        <div className='post-text-edit'>
                        <div className='post-cover-photo'>
    {!uploadedPhoto && !postsdata?.coverImgUrl && !coverImg? ( 
        // Conditionally render based on uploadedPhoto state
        <>
            <h2 className='cover-photo-h2'>Add cover photo here</h2>
            <h3 className='cover-photo-h3'>We recommend uploading or dragging in an image that is 1920x1080 pixels</h3>

            <div className='post-edit-cover-upload'>
            <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} />
            <button className='upload-cover-p' onClick={handleUploadButtonClick}>Upload from your computer</button>
            </div>
        </>
    ) : (
        // Ensure postsdata and postsdata.coverImgUrl are defined before rendering the image
        postsdata?.coverImgUrl ? <img 
                src={postsdata.coverImgUrl} 
                alt='Cover' 
                className='uploaded-cover-photo' 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            /> :<img 
            src={coverImg ||uploadedPhoto } 
            alt='Cover' 
            className='uploaded-cover-photo' 
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
        />
    )}
</div>
                            <div className='post-edit-content'>
                            <div className='bog-title'>
      <textarea 
        name="text" 
        rows="5" 
        cols="10" 
        wrap="soft" 
        
        placeholder={title ? title : 'ADD TITLE'}
        onChange={handtitleChange}
      >
      </textarea>
    </div>
                                <div className='start-typing'>
                                    <div className="app-content" style={{ width: "100%" }}>
                                        <Editor data={postData && postData.rawdata ? postData.rawdata : data} setData={setData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='post-side-bar'>
                            <div className='post-settings'><h3 className='post-settings-h3'>Post settings</h3></div>
                            <div className='side-settings'>

                                <div className='post-categories'>
                                    <div className='post-settings-wrapper'>
                                        <p className='post-setting-label'>categories</p>
                                        <div onClick={handleClick} className='post-settings-arrow'><SlArrowUp /></div>
                                 </div>
                                    <div className='divider'></div>
                                    {openCategory && (
                                        <ul className='categories-ul'>
                                            {checkedState.map((category, index) => (
                                                <Checkbox
                                                    key={category.id}
                                                    isChecked={category.checked}
                                                    checkHandler={() => handleOnChecked(index)}
                                                    label={category.name}
                                                    index={index}
                                                />
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div>
                                    {/* <div className='post-settings-wrapper'>
                                        <p className='post-setting-label'>Schedule Publish Date</p>
                                        <div ><SlArrowUp onClick={(event) => setPublish(current => !current)} /></div>
                                    </div>
                                    <div className='divider'></div>
                                    {openPublish && (
                                        <div className="post-radio">

                                            <label>    <input
                                                id='label-post'
                                                type="radio"
                                                value="automatic"
                                                checked={selectedOption === 'automatic'}
                                                onChange={handleOptionChange}
                                            />
                                                Automatic
                                            </label>
                                            <label>
                                                <input
                                                    id='label-post'
                                                    type="radio"
                                                    value="schedule"
                                                    checked={selectedOption === 'schedule'}
                                                    onChange={handleOptionChange}
                                                />
                                                Schedule
                                            </label>
                                        </div>
                                    )}
                                    {selectedOption === 'schedule' && openPublish && (
                                        <div>
                                            <div className='display-shedule'> <div className='selected-date-time'>
                                                <p>{selectedDate.toDateString()}</p>

                                            </div>
                                                <div className='selected-time'>
                                                    <p>10.46 AM</p>

                                                </div></div>
                                            <Calendar onChange={handleDateChange} value={value} />
                                        </div>
                                    )} */}
                                    <div>
                                        {/* <div className='post-settings-wrapper'>
                                            <p className='post-setting-label'>Comments</p>
                                            <div ><SlArrowUp onClick={(event) => setComment(current => !current)} /></div>

                                        </div> */}
                                        {/* <div className='divider'></div> */}
                                        {/* {openComment && (
                                            <div className="post-radio">
                                                <label>
                                                    <input
                                                        id='label-post'
                                                        type="radio"
                                                        value="Allow comments"
                                                        checked={commentOption === 'Allow comments'}
                                                        onChange={handleCommentChange}
                                                    />
                                                    Allow comments
                                                </label>
                                                <label>
                                                    <input
                                                        id='label-post'
                                                        type="radio"
                                                        value="Dont allow comments"
                                                        checked={commentOption === 'Dont allow comments'}
                                                        onChange={handleCommentChange}

                                                    />
                                                    Don't allow comments
                                                </label>
                                            </div>
                                        )} */}


                                    </div>

                                </div>

                                <div> <div className='post-settings-wrapper'>
                                    <p className='post-setting-label'>Author Name</p>
                                    <div ><SlArrowUp /></div>

                                </div>
                                    <div className='divider'></div>
                                    <div className='author-name'><p>{username}</p></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            <div>

                {openPreview && (
                    <Preview rawdata={data} setPreview={setPreview} setData={setData}  postId={postid} updatedPost={data}/>
                )}
            </div>
        </div>
    )
}
