import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PostHeroCard, PostCard } from '../../Features/Post.Card';
import userPic from '../../Components/Assets/Blog/Ellipse 1.svg'
import './index.css';
import dayjs from 'dayjs';
import { farmsellApi } from '../../Api/famsellAPi';
import { CiSearch } from 'react-icons/ci';
import { setPostsData, setRecentPosts } from '../../Components/Slices/Posts.slice';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RecentPosts } from '../../Features/postFeatures';
import OuterNavBar from '../OuterNavBar';
import Footer from '../Footer';
import { setLoading } from '../../Components/Slices/Loadind.slice';
import useFetchPosts from '../../Hooks/useFetchPosts';
import Loader from '../../utils/Loader';
import SeoComponent from '../../Features/SeoComponent';
export default function BlogIndex() {
  const dispatch = useDispatch();
  const [post, setPost] = useState([]);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { fetchPosts } = useFetchPosts('/post/recent', {
    params: {
      currentPage,
      pageSize: 6,
    }
  })
  const { fetchData } = useFetchPosts('/posts', {
    params: {
      currentPage,
      pageSize: 6,
    }
  })
  const BlogPosts = useSelector((state) => state.BlogPosts.posts);
  const recentPosts = useSelector((state) => state.BlogPosts.recentPosts);
  const loading = useSelector((state) => state.loading.loading);
  const rentLoading = useSelector((state)=> state.BlogPosts.rentPostsLoading)
  const postLoading = useSelector((state)=> state.BlogPosts.postsLoading)
  console.log("recent postss", recentPosts)

  useEffect(() => {
    fetchPosts();
    fetchData();
  }, [rentLoading,postLoading]);

  useEffect(() => {
    if (BlogPosts) {
      const formattedPosts = BlogPosts.map((post) => {
        const parsedData = post.data ? JSON.parse(post.data)?.rawdata : null;
        const parsedCategories = post.categories ? JSON.parse(post.categories).categories : [];

        return {
          key: post.id,
          data: parsedData,
          description: post.description,
          title: post.title,
          metaTitle: post.metaTitle,
          metadescription: post.metadescription,
          Categories: parsedCategories,
          coverPhoto: post.coverImgUrl,
          profilePhoto: post.user?.UserPic || userPic,
          username: post.user?.name || 'Unknown Author',
          Created: dayjs(post.createdAt).format('MMMM DD, YYYY'),
        };
      });
      setPost(formattedPosts);
    }

    if (recentPosts) {
      const formattedPosts = recentPosts.map((post) => {
        const parsedData = post.data ? JSON.parse(post.data)?.rawdata : null;
        const parsedCategories = post.categories ? JSON.parse(post.categories).categories : [];
        return {
          key: post.id,
          data: parsedData,
          description: post.description,
          title: post.title,
          metaTitle: post.metaTitle,
          metadescription: post.metadescription,
          Categories: parsedCategories,
          coverPhoto: post.coverImgUrl,
          profilePhoto: post.user?.UserPic || userPic,
          username: post.user?.name || 'Unknown Author',
          Created: dayjs(post.createdAt).format('MMMM DD, YYYY'),
        };
      });
      setPosts(formattedPosts)
    }
  }, [recentPosts, BlogPosts]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <div className='blog-wrapper-posts'>
        <OuterNavBar />

        <div className='header-wrapper'>
          <div className='categories'>
            <p>Agriculture</p>
            <p>Farm</p>
            <p>Casava</p>
          </div>
          <div className='search-header'>
            <input type='text' className='form-control' placeholder='Hi, what would you want to read today?' />
            
              <button className='search-icon'>
                <CiSearch />
              </button>
            
          </div>
        </div>
        <div className=''>


          <PostHeroCard post={posts} />



        </div>
        <div className='grid-container'>
          {loading ? (
            <Loader />
          ) : (
            post.map((postItem) => <PostCard key={postItem.key} post={postItem} />)
          )}
        </div>
        <div className='pagination'>
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='page-button'>
            &larr;
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`page-button ${currentPage === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='page-button'>
            &rarr;
          </button>
        </div>


      </div>
      <Footer />
    </>
  );
}
