import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AuthenticationRoute, PublicRoutes } from './utils/AuthenticationRoute'; // Import the AuthenticationRoute component
import SignIn from './Components/SignIn/SignIn';
import Blog from './Components/blogPost/Indexblog';
import Post from './Components/Posts/Post';
import Stat from './Components/Statistics/Stat';
import UserProfile from './Components/UserProfile/UserProfile';
import { Preview } from './Components/Preview/Preview';
import Blogpost from './Components/Posts/Blogpost';
import CreateAccount from './Components/CreateAcount/CreateAccount';
import { DataProvider } from './Components/Preview/GetData';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Supensing from './utils/Suspensing';
import Loader from './utils/Loader';
import withUserFetch from './utils/withUserFetch';
import Resetpassword from './Components/CreateAcount/Resetpassword';
import EmailSubmit from './Components/CreateAcount/EmailSubmit';
import BlogPostUI from './Pages/BlogpostUI/BlogPostUI';
import BlogIndex from './Pages/BlogPosts/Blogindexposts';
import { ErrorBoundary } from 'react-error-boundary';
// import BlogImport from './Features/BlogImport';
const FallbackComponent = ({ error, resetErrorBoundary }) => (
  <div role='alert'>
    <p>Something went wrong</p>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Click here to try again</button>
  </div>
);
function App() {
  const [somekey, setSomeKey] = useState(null)
 
  const isAuthenticated = useSelector(state => state.users.isAuthenticated);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    // Simulating an authentication check (you might have some async call here)
    if (isAuthenticated !== null) {
      setAuthChecked(true);
    }
  }, [isAuthenticated]);



  return (
    <GoogleOAuthProvider clientId="1056600415170-9cn2lo9gm3hftp21eono93i9sij75eg3.apps.googleusercontent.com">
      <BrowserRouter>
        <DataProvider>
          <ErrorBoundary
            FallbackComponent={FallbackComponent}
            onReset={() => {
              setSomeKey(null)
            }} 
            resetKeys={[somekey]}

          >
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/*" element={<SignIn />} />

              <Route path="/create" element={<CreateAccount />} />
              {/* Protected Routes */}
              {/* <Route path="/import" element={<BlogImport />} /> */}
              <Route path="/forgot_password" element={<Resetpassword />} />
              <Route path="/post/:title" element={<BlogPostUI />} />
              <Route path="/blogs" element={<BlogIndex />} />
              <Route path="/enter-email" element={<EmailSubmit />} />s
              {/* Use AuthenticationRoute for protected routes */}
              <Route element={<AuthenticationRoute />}>
                <Route path="/post" element={<Supensing Component={Post} />} />
                <Route path="/blog/:postid" element={<Blog />} />
                <Route path="/stat" element={<Supensing Component={Stat} />} />
                <Route path="/preview" element={<Supensing Component={Preview} />} />
                <Route path="/user" element={<Supensing Component={UserProfile} />} />
                <Route path="/post/:id" element={<Supensing Component={Blogpost} />} />
                <Route path="/blog" element={<Supensing Component={Blog} />} />
                <Route path="/blog/:id" element={<Supensing Component={Blog} />} />
              </Route>
              {/* Add a catch-all route for unknown routes */}

            </Routes>
          </ErrorBoundary>
        </DataProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default withUserFetch(App);
